import React from 'react';
import {useParams} from "react-router-dom";
import {GoUpButton} from "../component/GoUp";
import {isMobile} from "../utils/mobile";

function DetailScreen({data = []}) {

    const {key} = useParams();

    const filterData = data.filter(function (el) {
        return el.key === key;
    })[0];

    const selectedData = filterData ? filterData : {}

    const handleUpButton = () => {

    }

    let mediaComponents
    let imgModals

    const getImgId = (url) => {
        const parts = url.split("/");
        return url.split("/")[parts.length - 1].split(".")[0]
    }

    if (selectedData.media) {
        mediaComponents = selectedData.media.map((media) =>
            media.type === "img" ?
                <img key={selectedData.media.indexOf(media)}
                     data-toggle="modal" data-target={"#modal-" + getImgId(media.url)}
                     alt={"detail"} className="cont" src={media.url} style={{width: "100%"}}/>
                :
                <video key={selectedData.media.indexOf(media)} width="100%" controls>
                    <source src={media.url} type="video/mp4"/>
                </video>
        )

        imgModals = selectedData.media.filter(el => el.type === "img").map((media) =>
            <div key={"modal-" + getImgId(media.url)} className="modal fade" id={"modal-" + getImgId(media.url)}
                 tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <img alt={"zoom"} className="cont" src={media.url} style={{width: "100%"}}/>
                </div>
            </div>
        )

    }

    const dxContent = (<div className="container">
            <div className="row align-items-end titolo" style={{display:isMobile.any()?"none": ""}}>
                <div className="col-6"><div className={isMobile.any()?"element-title-small": "element-title"}>{selectedData.title}</div></div>
                <div className="col-6" style={{textAlign: "right"}}>{selectedData.category}</div>
            </div>
            <div dangerouslySetInnerHTML={{__html: selectedData.description}}/>
        </div>
    )


    const content = isMobile.any() ?

        <div className="col-sm-10 black mobile" style={{paddingTop: 60}}>

            <div className="container">
                <div className="row align-items-end titolo">
                    <div className="col-6"><div className={"element-title-small"}>{selectedData.title}</div></div>
                    <div className="col-6" style={{textAlign: "right"}}>{selectedData.category}</div>
                </div>
                {mediaComponents}
            </div>
            {dxContent}
            <GoUpButton onUp={handleUpButton}/>
        </div>
        :
        <>
            <div className="col-sm-6 black sx" style={{paddingTop: 50}}>
                <div style={{height: 44}}/>
                <div className="container" style={{height: 40}}>
                    {mediaComponents}

                    <GoUpButton onUp={handleUpButton}/>
                </div>
            </div>
            <div className="col-sm-4 blue dx">
                {dxContent}
            </div>
        </>


    return (
        <div className="row modulo1">
            <div className="col-sm-1 white"/>
            {content}
            <div className="col-sm-1 white"/>
            {imgModals}
        </div>
    );
}

export default DetailScreen;