import React from 'react';
import {Link} from "react-router-dom";
import {isMobile} from "../utils/mobile";


function HomepageEntry({element}) {

    return (
        <div className="container">
            <div className="row align-items-end titolo">
                <div className="col-6">
                    <div className={isMobile.any()?"element-title-small": "element-title"}>{element.title}</div>
                </div>
                <div className="col-6" style={{textAlign:"right"}}>
                    {element.category}
                </div>
            </div>
            <Link to={"/" + element.key}>
                <img className="cover" src={element.banner} style={{width: "100%"}} alt={"banner"}/>
            </Link>

        </div>
    )
}

export default HomepageEntry;








