import React from 'react';
import {isMobile} from "../utils/mobile";
import {GoUpButton} from "../component/GoUp";


function AboutScreen() {


    const dxContent =
        <div className="container">
            <div className="row align-items-end titolo">
            </div>
            <div>
                <img alt={"about"} src="img/about/Lara2.jpg"
                className={isMobile.any()?"cover mobile-style":"cover desktop-style"}/>
                <p
                    className={isMobile.any()?"mobile-style":"desktop-style"}>Ph. Reza Khatir</p>
            </div>
        </div>

    const sxContent =
        <div className="container">
            <div className="row align-items-end titolo">
                <div className="col-6"><div className={isMobile.any()?"element-title-small": "element-title"}>About</div></div>
                <div className="col-6" style={{textAlign: "right"}}/>
            </div>
            <p style={{width: "70%"}}>Sono una visual designer svizzera, residente a Lugano. <br/>
                Nel 2016 ho completato gli studi come creatrice di abbigliamento da donna, presso la SAMS di
                Viganello, in seguito ho ultimato la maturità artistica, allo CSIA a Lugano.
                Nel 2020, ho conseguito un Bachelor in comunicazione visiva con indirizzo in media interaction,
                presso la SUPSI di Lugano.
            </p>

            <div className="row align-items-end titolo">
                <div className="col-6"><div className={isMobile.any()?"element-title-small": "element-title"}>Contatti</div></div>

            </div>
            <p>Email:
                ballerini.lara@gmail.com</p>

        </div>

    const content = isMobile.any() ?

        <div className="col-sm-10 black mobile" style={{paddingTop: 60}}>
            {sxContent}
            {dxContent}
            <GoUpButton/>
        </div>
        :
        <>
            <div className="col-sm-6 black sx" style={{paddingTop: 50}}>
                {sxContent}
            </div>
            <div className="col-sm-4 blue dx">
                {dxContent}
            </div>
        </>

    return (
        <div className="row modulo1">
            <div className="col-sm-1 white"/>
            {content}
            <div className="col-sm-1 white"/>
        </div>
    );
}

export default AboutScreen;