import React, {useEffect} from 'react';
import {NavLink, Route, Switch} from 'react-router-dom';
import HomeScreen from "../screens/HomeScreen";
import AboutScreen from "../screens/AboutScreen";
import DetailScreen from "../screens/DetailSecreen";
import {isMobile} from "../utils/mobile";


export function AppLayout() {

    const [data, setData] = React.useState([]);

    useEffect(() => {
        fetch('/data/dati_portfolio.json')
            .then(response =>
                response.json()
                    .then(setData)
            )
            .catch(console.log)
    }, [])


    return (
        <>
            <div className="row modulo0">
                <div className="col-md-1"/>
                <div className={isMobile.any()?"col-5": "col-md-6"}>
                    <ul className="nav">
                        <li className={isMobile.any()?"nav-item-small nomec": "nav-item nomec"}>
                            <span className="nav-link nomec active">Lara Ballerini</span>
                        </li>
                    </ul>
                </div>
                <div className={isMobile.any()?"col-7": "col-md-4"}>
                    <ul className="nav justify-content-end">
                        <li  className={isMobile.any()?"nav-item-small nomec": "nav-item nomec"}>
                            <NavLink  activeStyle={{textDecoration:"underline"}} activeClassName={"active"} exact to={""} className="nav-link">
                                progetti
                            </NavLink>
                        </li>
                        <li  className={isMobile.any()?"nav-item-small nomec": "nav-item nomec"}>
                            <NavLink activeStyle={{textDecoration:"underline"}} activeClassName={"active"} to={"/about"} className="nav-link">
                                about
                            </NavLink>
                        </li>
                    </ul>

                </div>
                <div className="col-md-1"/>
            </div>


            <main>
                <Switch>
                    <Route exact path="/" component={() => <HomeScreen data={data}/>}/>
                    <Route exact path="/about" component={AboutScreen}/>
                    <Route path="/:key" component={() => <DetailScreen data={data}/>}/>
                </Switch>
            </main>

        </>
    )
}


export default AppLayout;