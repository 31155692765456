import React from 'react';
import HomepageEntry from "../component/HomepageEntry";
import {isMobile} from "../utils/mobile";
import {GoUpButton} from "../component/GoUp";


function HomeScreen({data = []}) {

    const sxData = data.filter(function (el) {
        return el.position === "LEFT";
    });

    const dxData = data.filter(function (el) {
        return el.position === "RIGHT";
    });

    const sxContent = sxData.map(el => <HomepageEntry key={el.key} element={el}/>)
    const dxContent = dxData.map(el => <HomepageEntry key={el.key} element={el}/>)
    const fullContent = data.map(el => <HomepageEntry key={el.key} element={el}/>)
    console.log(fullContent)

    const content = isMobile.any() ?

        <div className="col-sm-10 black mobile" style={{paddingTop: 60}}>
            {fullContent}
            <GoUpButton/>
        </div>
        :
        <>
            <div className="col-sm-6 black sx" style={{paddingTop: 100}}>
                {sxContent}
                <GoUpButton/>
            </div>

            <div className="col-sm-4 blue dx">
                {dxContent}
                <GoUpButton/>
            </div>
        </>

    return (
        <div className="row modulo1">
            <div className="col-sm-1 white"/>

            {content}
            <div className="col-sm-1 white"/>
        </div>
    );
}


export default HomeScreen;