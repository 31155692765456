import React from "react";
import {isMobile} from "../utils/mobile";

export const GoUpButton = () => {

    const goUp = () => {

        if (!isMobile.any()) {
            document.querySelector('.sx').scrollTop = 0;
            document.querySelector('.dx').scrollTop = 0;
        } else
            document.querySelector('html').scrollTop = 0;

    }

    return (
        <div className="container" style={{height: 40}} onClick={goUp}>
            <div className="row align-items-end titolo">
                <svg className="bi bi-arrow-up" width="2em" height="2em" viewBox="0 0 16 16" fill="currentColor"
                     xmlns="http://www.w3.org/2000/svg" onClick={goUp}>
                    <path fillRule="evenodd" d="M8 3.5a.5.5 0 01.5.5v9a.5.5 0 01-1 0V4a.5.5 0 01.5-.5z"
                          clipRule="evenodd"/>
                    <path fillRule="evenodd"
                          d="M7.646 2.646a.5.5 0 01.708 0l3 3a.5.5 0 01-.708.708L8 3.707 5.354 6.354a.5.5 0 11-.708-.708l3-3z"
                          clipRule="evenodd"/>
                </svg>
            </div>
        </div>
    )
}